<template>
  <div>
    <!-- Modal -->
    <div id="modal_aside_mapa" class="modal fixed-left fade" role="dialog">
      <div class="modal-dialog modal-dialog-aside" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera pt-1 pb-1">
            <h3 style="font-size: 16px" class="modal-title text-white">
              <b>Opciones Mapa</b>
            </h3>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row"></div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="empresa">Empresa</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    placeholder="Razon Social"
                    v-model="empresa"
                    label="razon_social"
                    :options="listas.empresas"
                    @input="selectEmpresa()"
                    style="font-size: 10px; background-color: #fff"
                    multiple
                  ></v-select>
                </div>
              </div>
              <div
                class="col-md-12"
                v-if="
                  $parent.form.vehiculos_in == true ||
                    $parent.form.vehiculos_na == true
                "
              >
                <div class="form-group">
                  <label for="estado">Estado del Servicio</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="estado"
                    label="descripcion"
                    :options="listas.estados"
                    style="font-size: 10px; background-color: #fff"
                    placeholder="Estados"
                    @input="selectEstadoServicio()"
                    multiple
                  ></v-select>
                </div>
              </div>
              <div
                class="form-group col-md-12"
                v-if="
                  $parent.form.vehiculos_in == true ||
                    $parent.form.vehiculos_na == true
                "
              >
                <label for="sitio">Sitio</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="sitio"
                  label="nombre"
                  :options="listas.sitios"
                  style="font-size: 10px; background-color: #fff"
                  placeholder="Sitios"
                  @input="selectSitio()"
                  multiple
                ></v-select>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="">Vehículo</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="vehiculo"
                    label="placa"
                    :options="listas.vehiculos"
                    style="font-size: 10px"
                    @input="selectVehiculo()"
                    multiple
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
              <i class="fas fa-times"></i>
            </button>
            <button
              type="button"
              class="btn btn-dark"
              @click="limpiarFiltros()"
            >
              Limpiar
              <i class="fas fa-trash"></i>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="$parent.showCarros()"
            >
              Buscar
              <i class="fas fa-search"></i>
            </button>
            <button
              v-if="$store.getters.can('cs.mapas.excelTraza')"
              type="button"
              class="btn bg-indigo text-white mr-2 align-top py-1"
              style="cursor: pointer"
              @click="$parent.generarListadoExcel()"
            >
              <i style="font-size: 21px" class="far fa-file-excel"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "CsMapasOptions",
  components: {
    vSelect,
  },
  data() {
    return {
      filter: {
        estado_servicio: [],
        empresa: [],
        estado_solicitud: [],
      },
      listas: {
        vehiculos: [],
        estados: [],
        empresas: [],
        conductores: [],
        sitios: [],
      },
      sitio: null,
      estado: null,
      empresa: null,
      vehiculo: null,
    };
  },

  methods: {
    getEstados() {
      axios.get("/api/lista/143").then((response) => {
        this.listas.estados = response.data;
      });
    },

    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listas.sitios = response.data;
      });
    },

    getEmpresa() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: 4,
          },
        })
        .then((response) => {
          this.listas.empresas = response.data;
        });
    },

    getVehiculos() {
      axios
        .get("/api/admin/vehiculos/lista", {
          params: {
            linea_negocio_id: 4,
          },
        })
        .then((response) => {
          this.listas.vehiculos = response.data;
        });
    },

    selectEmpresa() {
      this.filter.empresa_id = this.empresa.map((e) => e.id);
    },

    selectEstadoServicio() {
      this.filter.estado_servicio = this.estado.map((e) => e.numeracion);
    },

    selectVehiculo() {
      this.filter.vehiculo_id = this.vehiculo.map((e) => e.id);
    },

    selectSitio() {
      this.filter.sitio_id = this.sitio.map((e) => e.id);
    },

    buscarConductor(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/conductores/lista?nombre=" + search;
        axios
          .get(url, {
            params: {},
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listas.conductores = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    limpiarFiltros() {
      this.filter = {};
      this.filter.estado = [];
      this.filter.empresa = [];
      this.estado = null;
      this.sitio = null;
      this.empresa = null;
      this.vehiculo = null;
    },
  },
  mounted() {
    this.getEstados();
    this.getSitios();
    this.getEmpresa();
    this.getVehiculos();
  },
};
</script>
<style>
.modal .modal-dialog-aside {
  width: 440px;
  max-width: 50%;
  height: 100%;
  margin: 0;
  transform: translate(2);
  transition: transform 0.2s;
}
.modal .modal-dialog-aside .modal-content {
  height: inherit;
  border: 1;
  border-radius: 1;
}
.modal .modal-dialog-aside .modal-content .modal-body {
  overflow-y: auto;
}
.modal.fixed-left .modal-dialog-aside {
  margin-left: auto;
  transform: translateX(100%);
}
.modal.fixed-right .modal-dialog-aside {
  margin-right: auto;
  transform: translateX(-100%);
}
.modal.show .modal-dialog-aside {
  transform: translateX(0);
}
</style>
